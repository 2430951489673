export const GROUP_TYPES = [
  {value: 'tel_buyer_advisory_done', name: '譲渡企業(AD締結済)への買手候補リスト'},
  {value: 'tel_buyer', name: '譲渡企業への買手候補リスト'},
  {value: 'seller', name: '譲渡企業ソーシング(DM)'},
  {value: 'mail_seller', name: '譲渡企業ソーシング(手紙)'},
  {value: 'president', name: '譲渡企業ソーシング(社長手紙)'},
  {value: 'seller_planning', name: '譲渡企業ソーシング(営業企画DM)'},
  {value: 'carve_out', name: 'カーブアウトソーシング(DM)'},
  {value: 'transfer_from_buyer', name: '譲受→譲渡ソーシング(TEL)'},
  {value: 'buyer', name: '譲受企業ソーシング(DM)'}, 
  {value: 'unlimit', name: 'セミナーソーシング(DM)'},
  {value: 'project', name: 'SDSソーシング(手紙)'},
  {value: 'project_president', name: 'SDSソーシング(社長手紙)'},
  {value: 'kensetsu', name: '建設総研ソーシング(DM)'},
  {value: 'kensetsu_mail', name: '建設総研ソーシング(手紙)'},
  {value: 'kensetsu_president', name: '建設総研ソーシング(社長手紙)'},
  {value: 'touhoku', name: '東北総研ソーシング(DM)'},
  {value: 'touhoku_mail', name: '東北総研ソーシング(手紙)'},
  {value: 'touhoku_president', name: '東北総研ソーシング(社長手紙)'},
  {value: 'financial', name: 'M&Aプライムグループ(DM)'},
  {value: 'financial_mail', name: 'M&Aプライムグループ(手紙)'},
  {value: 'financial_president', name: 'M&Aプライムグループ(社長手紙)'},
  {value: 'financial_project', name: 'M&AプライムグループSDS(手紙)'},
  {value: 'financial_project_president', name: 'M&AプライムグループSDS(社長手紙)'},
  {value: 'executive', name: 'M&Aエグゼクティブ(DM)'},
  {value: 'executive_mail', name: 'M&Aエグゼクティブ(手紙)'},
  {value: 'executive_president', name: 'M&Aエグゼクティブ(社長手紙)'},
  {value: 'executive_project', name: 'M&AエグゼクティブSDS(手紙)'},
  {value: 'executive_project_president', name: 'M&AエグゼクティブSDS(社長手紙)'},
  {value: 'quants', name: 'クオンツコンサル(DM)'},
  {value: 'quants_mail', name: 'クオンツコンサル(手紙)'},
  {value: 'quants_president', name: 'クオンツコンサル(社長手紙)'},
  {value: 'accountant', name: '税理士被リンク営業(DM)'},
  {value: 'no_appointment', name: '飛び込み営業'},
];
